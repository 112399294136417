import { useReducer, useEffect } from 'react';

import { OctokitClient } from '../api';
import { Action, HookOptions, State } from '../types';
import {
  fetchIssues,
  fetchPullRequests,
  fetchRepos,
  fetchSearch,
  fetchReleases
} from '../services/dataFetchFunctions';

const dataFetchReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'FETCH_INIT':
      return { ...state, loading: true, error: null };
    case 'FETCH_SUCCESS':
      return { ...state, loading: false, data: action.payload, error: null };
    case 'FETCH_FAILURE':
      return { ...state, loading: false, error: action.payload };
    default:
      throw new Error('Unhandled action type');
  }
};

export const useOctokitData = (options: HookOptions) => {
  const { owner, repo, configApi, type, searchQuery, perPage } = options;
  const [state, dispatch] = useReducer(dataFetchReducer, {
    data: null,
    loading: true,
    error: null,
  });
  const client = new OctokitClient({ configApi });

  useEffect(() => {
    switch (type) {
      case 'issues':
        fetchIssues(options, client, dispatch);
        break;
      case 'pullRequests':
        fetchPullRequests(options, client, dispatch);
        break;
      case 'repos':
        fetchRepos(options, client, dispatch);
        break;
      case 'search':
        fetchSearch(options, client, dispatch);
        break;
      case 'releases':
        fetchReleases(options, client, dispatch);
        break;
      default:
        throw new Error('Invalid type specified');
    }
  }, [configApi, owner, type, repo, searchQuery, perPage]);

  return { ...state };
};
