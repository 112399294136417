import { ConfigApi } from '@backstage/core-plugin-api';
import {
  ListIssuesOptions,
  ListPullRequestsOptions,
  ListReleaseOptions,
  ListReposOptions,
  OctokitApi,
  OctokitClientOptions,
  SearchIssuesAndPullRequestsOptions,
} from '../types';

import { Octokit } from '@octokit/rest';

export class OctokitClient implements OctokitApi {
  private readonly octokit: Octokit;
  private readonly configApi: ConfigApi;

  constructor(options: OctokitClientOptions) {
    this.configApi = options.configApi;
    this.octokit = new Octokit({
      auth: this.configApi.getOptionalString('app.auth.octokit.token'),
    });
  }

  async listIssues(options: ListIssuesOptions) {
    const issues = await this.octokit.issues.listForRepo(options);
    return issues.data;
  }

  async listPullRequests(options: ListPullRequestsOptions) {
    const pullRequests = await this.octokit.pulls.list(options);
    return pullRequests.data;
  }

  async listRepos(options: ListReposOptions) {
    const repos = await this.octokit.repos.listForUser(options);
    return repos.data;
  }

  async searchIssuesAndPullRequests(options: SearchIssuesAndPullRequestsOptions) {
    const searchResponse = await this.octokit.search.issuesAndPullRequests(options);
    return searchResponse.data.items;
  }

  async listReleases(options: ListReleaseOptions) {
    const releases = await this.octokit.repos.listReleases(options);
    return releases.data;
  }

}
