import { OctokitClient } from "../api";
import { HookOptions, Action } from "../types";

export const fetchIssues = async (
  options: HookOptions,
  client: OctokitClient,
  dispatch: React.Dispatch<Action>
) => {
  const { repo, owner, perPage } = options;

  if (!repo || !owner) {
    dispatch({ type: 'FETCH_FAILURE', payload: new Error('Repository and owner names are required') });
    return;
  }

  try {
    const issuesResult = await client.listIssues({ repo: repo, owner: owner, per_page: perPage });
    dispatch({ type: 'FETCH_SUCCESS', payload: issuesResult });
  } catch (error) {
    dispatch({ type: 'FETCH_FAILURE', payload: new Error('Failed to fetch issues') });
  }
};

export const fetchPullRequests = async (
  options: HookOptions,
  client: OctokitClient,
  dispatch: React.Dispatch<Action>
) => {
  const { repo, owner, perPage } = options;
  if (!repo || !owner) {
    dispatch({ type: 'FETCH_FAILURE', payload: new Error('Repository and owner names are required') });
    return;
  }

  try {
    const pullRequestsResult = await client.listPullRequests({ repo, owner, per_page: perPage });
    dispatch({ type: 'FETCH_SUCCESS', payload: pullRequestsResult });
  } catch (error) {
    dispatch({ type: 'FETCH_FAILURE', payload: new Error('Failed to fetch pull requests') });
  }
};

export const fetchRepos = async (
  options: HookOptions,
  client: OctokitClient,
  dispatch: React.Dispatch<Action>
) => {
  const { owner, perPage } = options
  if (!owner) {
    dispatch({ type: 'FETCH_FAILURE', payload: new Error('Owner name is required') });
    return;
  }

  try {
    const reposResult = await client.listRepos({ username: owner, per_page: perPage });
    dispatch({ type: 'FETCH_SUCCESS', payload: reposResult });
  } catch (error) {
    dispatch({ type: 'FETCH_FAILURE', payload: new Error('Failed to fetch repositories') });
  }
};

export const fetchReleases = async (
  options: HookOptions,
  client: OctokitClient,
  dispatch: React.Dispatch<Action>
) => {
  const { repo, owner, perPage } = options
  if (!repo || !owner) {
    dispatch({ type: 'FETCH_FAILURE', payload: new Error('Repository and owner names are required') });
    return;
  }

  try {
    const releasesResult = await client.listReleases({ repo, owner, per_page: perPage });
    dispatch({ type: 'FETCH_SUCCESS', payload: releasesResult });
  } catch (error) {
    dispatch({ type: 'FETCH_FAILURE', payload: new Error('Failed to fetch releases') });
  }
}

export const fetchSearch = async (
  options: HookOptions,
  client: OctokitClient,
  dispatch: React.Dispatch<Action>
) => {
  const { searchQuery, perPage } = options;
  if (!searchQuery) {
    dispatch({ type: 'FETCH_FAILURE', payload: new Error('Search query is required') });
    return;
  }

  try {
    const searchResult = await client.searchIssuesAndPullRequests({ q: searchQuery, per_page: perPage });
    dispatch({ type: 'FETCH_SUCCESS', payload: searchResult });
  } catch (error) {
    dispatch({ type: 'FETCH_FAILURE', payload: new Error('Failed to search for issues and pull requests') });
  }
}
