import React from 'react';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

import {
  HomePageToolkit,
} from '@backstage/plugin-home';
import { Content, Page } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';

import NNLogoBlue from "../../assets/nn__logo--blue.png";
import NNLogoWhite from "../../assets/nn__logo--white.png";
import HomePageInfoBox from './HomePageInfoBox';
import { developerPortalLinks, novoNordiskLinks } from './toolkitLinks';
import { DiscoverCard } from './cards/DiscoverCard';
import { CollaborateCard } from './cards/CollaborateCard';
import { EngageCard } from './cards/EngageCard';

const HomePageContainer = styled(Grid)({
  margin: '0',
  width: '100%',
});

const CustomHomePageSearchBar = styled(HomePageSearchBar)(({ theme }) => ({
  '& .searchBarInput': {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px !important',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
  '& .searchBarOutline': {
    borderStyle: 'none',
  },
}));

const HeadingTitle = styled('h1')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '36px',
  fontWeight: 600,
  paddingLeft: '20px',
}));

const HeadingImage = styled('img')({
  placeSelf: 'center',
});

export const HomePage = () => {
  const theme = useTheme();
  const logoType = theme.palette.mode === 'dark' ? NNLogoWhite : NNLogoBlue;

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <HomePageContainer container justifyContent="center">
            <Grid container item xs={12} justifyContent='center'>
              <HeadingImage src={logoType} alt='Novo Nordisk logo' height={80} />
              <HeadingTitle>Developer Portal</HeadingTitle>
            </Grid>
            <Grid container item md={8} xs={12} justifyContent='center'>
              <CustomHomePageSearchBar
                InputProps={{
                  classes: {
                    root: 'searchBarInput', notchedOutline: 'searchBarOutline'
                  }
                }}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={12} mt={2} justifyContent="center">
              <HomePageInfoBox />
            </Grid>
            <Grid container mt={1}>
              <Grid item md={6} xs={12}>
                <HomePageToolkit
                  title="Developer Experience Links"
                  tools={developerPortalLinks}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <HomePageToolkit
                  title="Novo Nordisk Links"
                  tools={novoNordiskLinks}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="space-between">
              <DiscoverCard />
              <CollaborateCard />
              <EngageCard />
            </Grid>
          </HomePageContainer>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
