import React, { useState, useEffect } from "react";

import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { useOctokitData } from "@internal/backstage-plugin-octokit-wrapper";

import { Button, CardContent, CardActions, Grid, Box, CircularProgress } from "@mui/material";
import GitHubIcon from '@mui/icons-material/GitHub';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { CardListItem, CardListItemDate, CardListItemName, CardSubtitle, HomePageInfoCard } from "../styled";
import { GithubPullRequestOrIssue } from "../../../types";

export const CollaborateCard = () => {
  const [openPullRequests, setOpenPullRequests] = useState<GithubPullRequestOrIssue[]>([]);
  const configApi = useApi(configApiRef);
  const owner = 'innersource-nn';
  const type = 'search';
  const perPage = 5;

  const { data, loading } = useOctokitData({
    owner,
    configApi,
    type,
    perPage,
    searchQuery: "org:innersource-nn+is:open+type:pr"
  });

  useEffect(() => {
    if (loading) {
      return;
    }

    const fetchedPullRequests: GithubPullRequestOrIssue[] =
      data?.map((pullRequest) => ({
        id: pullRequest.id,
        title: pullRequest.title,
        htmlUrl: pullRequest.html_url,
        createdAt: pullRequest.created_at,
        user: {
          login: pullRequest.user?.login || 'N/A',
        },
      })) || [];

    setOpenPullRequests(fetchedPullRequests);
  }, [loading, data]);

  return (
    <HomePageInfoCard title="Collaborate in Inner-Source-NN">
      <CardContent>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <CardSubtitle>recently created <b>pull requests</b> in all repositories</CardSubtitle>
          </Grid>
          {loading ?
            (
              <Box display="flex" justifyContent="center" width="100%" p={2}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {!!openPullRequests && openPullRequests.length > 0 ? (
                  openPullRequests.map((pullRequest) => (
                    <Grid item key={pullRequest.id}>
                      <CardListItem>
                        <CardListItemDate>{new Date(pullRequest.createdAt).toLocaleDateString()}</CardListItemDate>
                        <CardListItemName>"{pullRequest.title}" by: <b>{pullRequest.user.login}</b></CardListItemName>
                        <Button
                          component="a"
                          href={pullRequest.htmlUrl}
                          target="_blank"
                          rel="noopener"
                          style={{ minWidth: 'fit-content' }}
                        >
                          <OpenInNewIcon fontSize="small" />
                        </Button>
                      </CardListItem>
                    </Grid>
                  ))
                ) : (
                  <Grid item>
                    <CardListItem>No open pull requests found</CardListItem>
                  </Grid>
                )}
              </>
            )
          }
        </Grid>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            component="a"
            target="_blank"
            rel="noopener"
            href={`https://github.com/${owner}`}
            startIcon={<GitHubIcon />}
          >
            <b>novo nordisk innersource</b>
          </Button>
        </Box>
      </CardActions>
    </HomePageInfoCard>
  );
};
